<template>
	<div class="reset-password">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="账号:">
				{{userName}}
			</el-form-item>
			<el-form-item label="旧密码:" prop="oldpassword">
				<el-input v-model="ruleForm.oldpassword" type="password" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="新密码:" prop="password" label-width="100px">
				<el-input v-model="ruleForm.password" type="password" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="确认密码:" prop="checkpassword" label-width="100px">
				<el-input v-model="ruleForm.checkpassword" type="password" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">重置密码</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			var checkNewPw = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请再次输入密码'));
				}
				if (this.ruleForm.password !== value) {
					callback(new Error("密码不一致"));
				} else {
					callback()
				}
			};
			return {
				userName: "",
				from: '',
				ruleForm: {
					oldpassword: '',
					password: '',
					checkpassword: ''
				},
				rules: {
					oldpassword: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					checkpassword: [{
						required: true,
						validator: checkNewPw,
						trigger: 'blur'
					}]
				}
			};
		},
		created() {
			// let token = localStorage.getItem("token");
			let userName = localStorage.getItem("name");
			if (userName) {
				this.userName = userName;
			} else {
				this.userName = "";
			}
			if (this.$route.query.from) {
				this.from = this.$route.query.from;
			}
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.fetchPost("/api/admin/changepwd", {
							oldpassword: this.ruleForm.oldpassword,
							password: this.ruleForm.password,
						}).then((res) => {
							if (res.data.code == 0) {
								this.$message.success("修改成功，请重新登录");
								localStorage.clear()
								if (this.from) {
									this.$router.push({
										path: this.from
									})
								} else {
									this.$router.push({
										path: '/login'
									})
								}
							} else {
								this.$message.error(res.data.desc);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
